
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { SnowplowPersonContext } from '@/helpers/tracking/providers/snowplow-contexts';
import { trackTitleDetailRedesignNewBuyBoxExp } from '@/pages/titleDetailV1/tracking';

const SearchSuggester = namespace('searchSuggester');

@Component({
	name: 'TitleCreditName',
})
export default class TitleCreditName extends Vue {
	@Prop({ default: '', type: String }) name: string;
	@Prop({ default: 0, type: Number }) id: number;
	@Prop(String) role: string | undefined;

	@SearchSuggester.Action goToSearch: ({
		searchString,
		personId,
	}: {
		searchString: string;
		personId?: number;
	}) => void;

	clicked(searchString: { searchString: string; personId: number }) {
		this.goToSearch(searchString);
		const personContext = new SnowplowPersonContext(searchString.personId, searchString.searchString);
		TrackingHelper.trackEvent(
			'persons_credit_name',
			{
				action: 'clicked',
				label: this.role,
				property: searchString.personId + '',
			},
			[personContext]
		);

		//TITLE_DETAIL_REDESIGN_NEW_BUYBOX
		trackTitleDetailRedesignNewBuyBoxExp({ action: 'click', label: 'persons_credit_name' });
		//TITLE_DETAIL_REDESIGN_NEW_BUYBOX
	}
}
