
import Vue from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';

import { PlayerIcon } from '@/enums/player-icon';

@Component({
	name: 'VideoPlayerWrapper',
})
export default class VideoPlayerWrapper extends Vue {
	@Prop({ default: false }) isActive: boolean;
	@Prop({ default: false }) isBackdrop: boolean; // Show preview image on background while video playing
	@Prop({ default: null }) playIconType: PlayerIcon;
	@Prop({ required: false, default: false }) isDisneyPlusOffer: boolean;

	get playIcon() {
		const extension = this.formattedPlayIconType === PlayerIcon.YOUTUBE ? 'png' : 'svg';
		return `url(/${ASSETS_DIR}/img/${this.formattedPlayIconType}-icon-play.${extension})`;
	}

	get formattedPlayIconType() {
		if (this.playIconType) {
			return this.playIconType;
		}
		return this.isBackdrop ? PlayerIcon.JUSTWATCH : PlayerIcon.YOUTUBE;
	}

	@Emit('play')
	play() {}
}
