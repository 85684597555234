import { WebLocale } from '@/enums/web-locale';
import { adSlots } from './ad-helper';

declare global {
	interface Window {
		yieldlove_site_id: string;
		yieldlove_prevent_autostart: boolean;
	}
}

type GoogleSlot = {
	name: string;
	path: string;
	sizes: number[][];
};

const slots = {} as Record<string, GoogleSlot[]>;

function getSlots(webLocale: WebLocale) {
	// defaulting to 420px because 50% of the all users are coming around this width taken from analytics
	const innerWidth = process.client ? window.innerWidth : 420;
	if (!slots[webLocale]) {
		slots[webLocale] = adSlots.map(slotItem => {
			return {
				path: getSlotPath(webLocale, slotItem.path),
				name: slotItem.name.replace('{WEBLOCALE}', webLocale),
				sizes: slotItem.sizes
					.filter(size => !size.breakpoint || size.breakpoint <= innerWidth)
					.map(size => [size.x, size.y]),
			};
		});
	}
	return slots[webLocale];
}

export function getSlotPath(webLocale: WebLocale, path: string) {
	return path
		.replace('{WEBLOCALE}', webLocale)
		.replace('/21679119891/', `/53015287,21679119891/justwatch.com/justwatch.com_${webLocale}/`);
}
/**
 * get biggest slot size placeholder.
 * whatever slot added
 */
const getYieldloveMaxSlotSize = (webLocale: WebLocale, slotId: string) => {
	const slot = getSlots(webLocale).find(slot => slot.name === slotId);
	let size: number[] | undefined;
	if (slot && slot.sizes) {
		size = slot.sizes.reduce(
			(root: number[], item: number[]) => {
				return root[0] < item[0] ? item : root;
			},
			[0, 0]
		);
	}
	return {
		x: (size || [])[0],
		y: (size || [])[1],
	};
};

/**
 * All yieldlove_v1 locales used the first version of the yieldlove script
 * and yieldlove_v2 uses the second version.
 * the plan here is to see how v2 is performimg before migrating all the v1 locales to it.
 */

//AT, DK, FI, NO, SE
const yieldlove_v1 = [
	WebLocale.au,
	WebLocale.ca,
	WebLocale.ch,
	WebLocale.es,
	WebLocale.fr,
	WebLocale.it,
	WebLocale.pt,
	WebLocale.uk,
	WebLocale.us,
];
const yieldlove_v2 = [
	WebLocale.de,
	WebLocale.co,
	WebLocale.tr,
	WebLocale.ar,
	WebLocale.at,
	WebLocale.dk,
	WebLocale.fi,
	WebLocale.no,
	WebLocale.se,
	WebLocale.mx,
	WebLocale.in,
	WebLocale.br,
];
const loadAdYieldlove = (webLocale: WebLocale, resolve: () => void) => {
	window.yieldlove_site_id = 'justwatch.com_' + webLocale;
	window.yieldlove_prevent_autostart = true;

	// include yieldlove bidder
	const c = document.getElementsByTagName('script')[0];
	const d = document.createElement('script');
	const e = yieldlove_v1.includes(webLocale)
		? 'https://cdn-a.yieldlove.com/yieldlove-bidder.js?justwatch.com_' + webLocale
		: 'https://cdn-a.yieldlove.com/v2/yieldlove.js?justwatch.com';

	d.type = 'text/javascript';
	d.src = e;
	d.async = true;
	d.defer = true;

	c.parentNode!.insertBefore(d, c);

	window.googletag = window.googletag || {};
	window.googletag.cmd = window.googletag.cmd || [];

	// dfp vendor
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.async = true;
	script.defer = true;
	if (yieldlove_v2.includes(webLocale)) {
		script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
	} else {
		script.src = 'https://www.googletagservices.com/tag/js/gpt.js';
	}
	document.body.appendChild(script);

	initSlots(webLocale, resolve);
};

const initSlots = (webLocale: WebLocale, resolve: () => void) => {
	window.googletag.cmd.push(() => {
		getSlots(webLocale).forEach(slot => {
			// only 1 size may be sent according to Vinh
			const maxSlotSize = getYieldloveMaxSlotSize(webLocale, slot.name);
			// window.googletag.defineSlot(slot.path, slot.sizes, slot.name).addService(window.googletag.pubads());
			window.googletag
				.defineSlot(slot.path, [maxSlotSize.x, maxSlotSize.y], slot.name)
				.addService(window.googletag.pubads());
		});
		window.googletag.pubads().enableSingleRequest();
		if (yieldlove_v2.includes(webLocale)) {
			window.googletag.pubads().disableInitialLoad(); /// add only for v2
		}
		window.googletag.enableServices();

		// set as ready
		resolve();
	});
};
const yieldloveWebLocales = [...yieldlove_v1, ...yieldlove_v2];

export { getYieldloveMaxSlotSize, loadAdYieldlove, yieldloveWebLocales };
