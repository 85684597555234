
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { SnowplowContext, SnowplowTitleContextGraphql } from '@/helpers/tracking/providers';
import {
	SetInDislikelistDocument,
	SetInDislikelistMutation,
	SetInDislikelistMutationVariables,
} from '@/graphql/mutation/SetInDislikelist.mutation';
import { TitleListName } from '@/interfaces/responses/title-list';
import { TrackingHelper, TrackingListEventPayload } from '@/helpers/tracking/tracking-helper';
import { addTitleToListToast, removeTitleFromListToast } from '@/helpers/toast/toast-helper';
import { preventNonSignedInAction } from '@/helpers/prevent-non-signed-in-action-helper';
import { ListMutationTitleDetailParam, TitleQuickActionPayload } from '@/helpers/providers/title-actions-provider';

@Component
export default class TitleDislikelistMutationMixin extends Vue {
	@Prop({ default: () => [] }) additionalContexts: SnowplowContext[];

	async mixin_setInDislikelist(
		titleId: string,
		isTitleInDislikeList: boolean,
		isTitleInLikeList: boolean,
		titleDetails: ListMutationTitleDetailParam,
		trackingPayload?: {
			source?: string;
			property?: string;
		},
		onComplete?: () => void
	) {
		const preventAction = await preventNonSignedInAction();

		if (preventAction) {
			return null;
		}

		return this.$apollo
			.mutate<SetInDislikelistMutation, SetInDislikelistMutationVariables>({
				mutation: SetInDislikelistDocument,
				variables: {
					input: {
						id: titleId,
						state: !isTitleInDislikeList,
					},
				},
				update: () => {
					const source = trackingPayload?.source ?? '';

					const contexts = [...this.additionalContexts];
					const hasTitleContext = contexts.find(context => context.__name === 'title');

					if (!hasTitleContext) {
						contexts.push(
							new SnowplowTitleContextGraphql(
								titleDetails.objectId,
								titleDetails.objectType,
								titleDetails.seasonNumber,
								titleDetails.episodeNumber,
								titleDetails.contentType
							)
						);
					}
					if (onComplete) {
						onComplete();
					}
					if (trackingPayload) {
						const payload: TrackingListEventPayload = {
							action: source,
						};

						if (trackingPayload.property) {
							payload.property = trackingPayload.property;
						}

						isTitleInLikeList && this.trackLikelistRemoveEvent(payload, contexts);
						isTitleInDislikeList
							? this.trackDislikelistRemoveEvent(payload, contexts)
							: this.trackDislikelistAddEvent(payload, contexts);
					}

					if (!isTitleInDislikeList) {
						addTitleToListToast(TitleListName.DISLIKELIST, titleDetails.title, () =>
							this.mixin_setInDislikelist(titleId, true, false, titleDetails, trackingPayload)
						);
					} else {
						removeTitleFromListToast(TitleListName.DISLIKELIST, titleDetails.title);
					}
				},
			})
			.then(() => {
				const payload: TitleQuickActionPayload = {
					titleObjectId: titleDetails.objectId,
					type: TitleListName.DISLIKELIST,
					isTitleInList: isTitleInDislikeList,
					objectType: titleDetails.objectType,
				};
				this.$emit('title-quick-action', payload);
			});
	}

	trackDislikelistAddEvent(payload: TrackingListEventPayload, contexts: SnowplowContext[]) {
		TrackingHelper.trackListEvent(TitleListName.DISLIKELIST, 'add', payload, contexts);
	}

	trackDislikelistRemoveEvent(payload: TrackingListEventPayload, contexts: SnowplowContext[]) {
		TrackingHelper.trackListEvent(TitleListName.DISLIKELIST, 'remove', payload, contexts);
	}

	trackLikelistRemoveEvent(payload: TrackingListEventPayload, contexts: SnowplowContext[]) {
		TrackingHelper.trackListEvent(TitleListName.LIKELIST, 'remove', payload, contexts);
	}
}
