
import { Vue, Component, Prop } from 'vue-property-decorator';

import { GuideEntryFragment } from '@/pages/graphql/queries/GetGuideArticles.query';
import { AssertByTypename } from '@/helpers/graphql-model-helper';
import pictureCompHelper from '@/helpers/picture-comp-helper';

@Component({ name: 'GuideItem' })
export default class GuideItem extends Vue {
	@Prop({ default: 3 }) clamp: number;
	@Prop({ default: true }) showParagraph: boolean;
	@Prop({ required: true }) item!: GuideEntryFragment;
	@Prop({ default: 'standard' }) variant: 'featured' | 'standard' | 'list';
	@Prop({ default: false }) h4Heading: boolean;
	@Prop() index: number;

	get itemTitle() {
		// the content is an HTML string so this slices out the <h1> tags
		if (this.item.content.title == null) return '';
		return this.item.content.title
			.substring(4, this.item.content.title.length - 5)
			.replaceAll('<h1>', '')
			.replaceAll('</h1>', '');
	}

	get mainObject() {
		type Node = GuideEntryFragment['mainObjects'][number]['node'];
		type MainObject = AssertByTypename<Node, 'GenericTitleList' | 'Movie' | 'Show' | 'Season'>;
		return this.item.mainObjects[0]?.node as MainObject;
	}

	get backdrop() {
		if (!!this.mainImageEntityBackdrop) return `https://images.justwatch.com${this.mainImageEntityBackdrop}`;

		if (!this.mainObject) return null;

		let backdropUrl: string | null = null;

		if (
			(this.mainObject.__typename === 'GenericTitleList' &&
				(this.mainObject.titles.edges ?? [])[0]?.node.content.backdrops[this.index]) ||
			(this.mainObject.__typename !== 'GenericTitleList' && this.mainObject.content.backdrops[this.index])
		) {
			backdropUrl =
				this.mainObject.__typename === 'GenericTitleList'
					? (this.mainObject.titles.edges ?? [])[0]?.node.content.backdrops[this.index]?.backdropUrl
					: this.mainObject.content.backdrops[this.index]?.backdropUrl;
		} else {
			backdropUrl =
				this.mainObject.__typename === 'GenericTitleList'
					? (this.mainObject.titles.edges ?? [])[0]?.node.content.backdrops[0]?.backdropUrl
					: this.mainObject.content.backdrops[0]?.backdropUrl;
		}

		if (!backdropUrl) return null;

		const profile = this.variant === 'featured' ? 'backdrop' : 'article_backdrop';
		return pictureCompHelper.getDefaultImageUrl(backdropUrl, profile, '', this.$store.state, false);
	}

	get mainImageEntityBackdrop() {
		return !!this.item?.content?.mainImageEntityBackdrop?.backdropUrl
			? this.item.content.mainImageEntityBackdrop.backdropUrl
			: '';
	}

	// TRACKING
	trackClick(e: Event) {
		const target = e.target as HTMLElement;
		if (target.nodeName !== 'A') return;

		const label = (target as HTMLAnchorElement).href;
		const property = this.mainObject.__typename === 'GenericTitleList' ? 'editorial-list' : 'news';
		this.$emit('content-clicked', { label, property });
	}
}
