import { WEB_LOCALES } from '@/constants/web-locales.constant';
import { WebLocale } from '@/enums/web-locale';
import { getSlotPath, loadAdYieldlove, yieldloveWebLocales } from '@/helpers/ad/yieldlove-helper';
import { ReadyType } from '@/stores/ready.store';
import Vue from 'vue';
import { AdsenseHelper } from './adsense-helper';
import { hasUCConsentBanner } from '../tracking';

export const enum AdProvider {
	YIELDLOVE = 'yieldlove',
	ADSENSE = 'adsense',
}

export const AdProviderLocales: { [key: string]: AdProvider } = Object.keys(WEB_LOCALES).reduce((root, webLocale) => {
	(root as any)[webLocale] = getProviderByCountry(webLocale as WebLocale);
	return root;
}, {} as Record<keyof typeof WEB_LOCALES, AdProvider>);

export const adSlots = [
	{
		name: 'jw_{WEBLOCALE}_top',
		path: '/21679119891/jw_{WEBLOCALE}_top',
		sizes: [
			{ x: 320, y: 50, breakpoint: 310, adsenseSlot: 5594148909 },
			{ x: 728, y: 90, breakpoint: 825, adsenseSlot: 2516381812 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_inlist_1',
		path: '/21679119891/jw_{WEBLOCALE}_inlist_1',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 970, y: 250, breakpoint: 1106, adsenseSlot: 1566723844 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_inlist_2',
		path: '/21679119891/jw_{WEBLOCALE}_inlist_2',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 970, y: 250, breakpoint: 1106, adsenseSlot: 1566723844 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_inlist_3',
		path: '/21679119891/jw_{WEBLOCALE}_inlist_3',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 970, y: 250, breakpoint: 1106, adsenseSlot: 1566723844 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_under_detail_info',
		path: '/21679119891/jw_{WEBLOCALE}_under_detail_info',
		sizes: [{ x: 300, y: 600, breakpoint: 992, adsenseSlot: 9468572974 }],
	},
	{
		name: 'jw_{WEBLOCALE}_under_buybox',
		path: '/21679119891/jw_{WEBLOCALE}_under_buybox',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 728, y: 90, breakpoint: 1130, adsenseSlot: 2516381812 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_bottom_page',
		path: '/21679119891/jw_{WEBLOCALE}_bottom_page',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 728, y: 90, breakpoint: 1130, adsenseSlot: 2516381812 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_sport_under_offers',
		path: '/21679119891/jw_{WEBLOCALE}_sport_under_offers',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 728, y: 90, breakpoint: 1130, adsenseSlot: 2516381812 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_sport_under_details',
		path: '/21679119891/jw_{WEBLOCALE}_sport_under_details',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 728, y: 90, breakpoint: 1130, adsenseSlot: 2516381812 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_sport_inlist_1',
		path: '/21679119891/jw_{WEBLOCALE}_sport_inlist_1',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 970, y: 250, breakpoint: 1106, adsenseSlot: 1566723844 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_sport_inlist_2',
		path: '/21679119891/jw_{WEBLOCALE}_sport_inlist_2',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 970, y: 250, breakpoint: 1106, adsenseSlot: 1566723844 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_sport_inlist_3',
		path: '/21679119891/jw_{WEBLOCALE}_sport_inlist_3',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 970, y: 250, breakpoint: 1106, adsenseSlot: 1566723844 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_sport_inlist_4',
		path: '/21679119891/jw_{WEBLOCALE}_sport_inlist_4',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 970, y: 250, breakpoint: 1106, adsenseSlot: 1566723844 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_sport_inlist_5',
		path: '/21679119891/jw_{WEBLOCALE}_sport_inlist_5',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 970, y: 250, breakpoint: 1106, adsenseSlot: 1566723844 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_sport_inlist_6',
		path: '/21679119891/jw_{WEBLOCALE}_sport_inlist_6',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 970, y: 250, breakpoint: 1106, adsenseSlot: 1566723844 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_sport_inlist_7',
		path: '/21679119891/jw_{WEBLOCALE}_sport_inlist_7',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 970, y: 250, breakpoint: 1106, adsenseSlot: 1566723844 },
		],
	},
];

function getProviderByCountry(webLocale: WebLocale): AdProvider {
	if (yieldloveWebLocales.includes(webLocale)) {
		return AdProvider.YIELDLOVE;
	}
	return AdProvider.ADSENSE;
}

// Declare Google Tag (DFP) and YieldLove Variables in Window object.
declare global {
	interface Window {
		// dfp
		googletag: {
			cmd: (() => void)[];
			pubads: any;
			defineSlot: any;
			enableServices: any;
			display: any;
		};
		// yieldlove
		YLHH: {
			bidder: any;
		};
	}
}

const ready$: Record<string, null | Promise<void>> = {};

const getAdProvider = (webLocale: WebLocale, isSport: boolean) => {
	if (isSport) {
		return AdProvider.ADSENSE;
	}
	return AdProviderLocales[webLocale];
};

const whenReady = async (webLocale: WebLocale, adProviderSelected: AdProvider) => {
	if (!global.VUE_APP_TESTS_E2E && hasUCConsentBanner(webLocale)) {
		// note: non-UC countries (regarding consent) should load the ads immediately.
		// that's why only UC countries will wait for `CONSENT_PARTNERS_ANSWERED`.
		await Vue.$jw.ready?.waitFor(ReadyType.CONSENT_PARTNERS_ANSWERED);
	}

	if (!ready$[adProviderSelected]) {
		switch (adProviderSelected) {
			case AdProvider.YIELDLOVE:
				ready$[AdProvider.YIELDLOVE] = new Promise<void>((resolve, reject) =>
					loadAdYieldlove(webLocale, resolve)
				);
				break;
			case AdProvider.ADSENSE:
				ready$[AdProvider.ADSENSE] = new Promise<void>((resolve, reject) =>
					AdsenseHelper.load(webLocale, resolve)
				);
				break;
		}
	}
	return ready$[adProviderSelected];
};

const loadAd = async (
	webLocale: WebLocale,
	slotId: string,
	adProviderSelected: AdProvider,
	onSlotReceived?: (filled: boolean) => void
) => {
	await whenReady(webLocale, adProviderSelected);

	switch (adProviderSelected) {
		case AdProvider.YIELDLOVE:
			window.googletag.cmd.push(() => {
				if (window.YLHH) {
					window.YLHH.bidder.startAuction(getSlotPath(webLocale, `/21679119891/${slotId}`));
				}

				window.googletag.cmd.push(function () {
					window.googletag.display(slotId);
				});

				window.googletag.pubads().addEventListener('slotResponseReceived', (evt: any) => {
					const { slot } = evt;
					if (slotId === slot.getSlotElementId()) {
						onSlotReceived?.(slot.getResponseInformation() !== null);
					}
				});
			});
			break;
		case AdProvider.ADSENSE:
			(window.adsbygoogle = window.adsbygoogle || []).push({});
			break;
		default:
			break;
	}
};

const removeAd = async (webLocale: WebLocale, slotId: string, adProviderSelected: AdProvider) => {
	await whenReady(webLocale, adProviderSelected);

	switch (adProviderSelected) {
		case AdProvider.YIELDLOVE:
			window.googletag.cmd.push(function () {
				window.googletag.pubads().clear([slotId]);
			});
			break;
	}
};

export { getAdProvider, loadAd, removeAd, whenReady };
